import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/layouts";
import setup from "@/utils/setup";
import NProgress from "nprogress";
import "nprogress/nprogress.css";

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

Vue.use(ElementUI, {
  size: "small",
});

Vue.use(setup);

Vue.config.productionTip = false;
NProgress.configure({
  easing: "ease",
  speed: 500,
  showSpinner: false,
  trickleSpeed: 200,
  minimum: 0.3,
});
router.beforeEach((to, from, next) => {
  NProgress.start();
  next();
});
router.afterEach(() => {
  NProgress.done();
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

// store.commit("initMenu", store.state)
